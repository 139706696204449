import { Component, OnInit } from "@angular/core";
import { ManagerService } from "src/app/services/manager.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ExportService } from "src/app/services/export.service";
import { SetupService } from "src/app/services/setup.service";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute } from "@angular/router";

declare var $: any;
@Component({
  selector: "app-session",
  templateUrl: "./session.component.html",
  styleUrls: ["./session.component.scss"],
})
export class SessionComponent implements OnInit {
  session: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  exp: any[];
  id: number;
  type: any[] = ["INITIALIZATION", "RESPONSE", "RELEASE"];
  view: boolean = false; report = false;
  tran: any;
  response:any; search = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }

  constructor(
    private api: ApiService,
    private set: SetupService,
    private ext: ExportService,
    private use: NavComponent,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      console.log(page,limit,search);
      this.getAllSessions(page,limit,search)
    })
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, type: null, code: null }
    // this.getAllSessions();
    this.getMerchant();
  }

  getMerchant() {
    this.api.get('Merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Merchant List');
    });
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllSessions(page=1,limit=12,search='') {
    this.api.get(`Session/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.session = data.data;
        this.response = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Session List"
        );
      }
    );
  }

  deleteSession(val) {
    this.api.delete("Session/Delete/" + val.id).subscribe(
      (data: any) => {
        this.getAllSessions();
        this.use.showMsg('success', 'Deleted', 'Session Was Deleted Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Delete Session"
        );
      }
    );
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    if (this.rpt.type == "SEND") {
      this.rpt.settled = true;
    }
    this.api.post("Session/Report", this.rpt).subscribe(
      (data: any) => {
        this.session = data.data;
        this.filtered = data.data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Session List"
        );
      }
    );
  }

  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "items");
      this.use.showMsg(
        "success",
        "Wallets Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Wallets Export Fail",
        "There is no Wallet Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id,
        CODE: e.tranid,
        ACCOUNT: e.account,
        TYPE: e.type,
        MOBILE: e.mobile,
        NETWORK: e.network,
        SERVICE: e.service,
        AMOUNT: e.amount,
        TRANSACTION_REF: e.transaction_ref,
        REFERENCE: e.reference,
        RESPONSE: e.response,
        CREATEDDATE: e.date,
      };
      this.exp.push(ed);
      this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    return this.session.filter(
      (a) =>
        a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
        a.network
          .toLowerCase()
          .indexOf(
            searchString.toLocaleLowerCase() ||
              a.mobile.indexOf(searchString) !== -1 ||
              a.reference.indexOf(searchString) !== -1
          ) !== -1
    );
  }
}
