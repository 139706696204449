import { Injectable } from '@angular/core';
import { Router, Routes, RouterStateSnapshot } from '@angular/router';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpUserEvent, HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { ErrorsService } from '../services/errors.service';
import { ApiService } from '../services/api.service';
// import getMAC, { isMAC } from 'getmac'
// import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
    state: RouterStateSnapshot; url: any; cur: any; user: any;
    constructor(private router: Router, private jwtHelper: JwtHelperService, private api: ApiService, private error: ErrorsService) {
        // console.log(getMAC());
    }
    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        this.cur = req.url;
        this.url = this.cur.split('/').slice(-2)[0]; // console.log(this.url);
        // this.user = this.api.user();
        if (this.url === 'upload') {
            req = req.clone({
                setHeaders: {
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                },
            });
        } else {
            // if(req.body && req.body.userid == null) req.body.userid = this.user.id;
            // if(req.body && req.body.merchantid == null) req.body.merchantid = this.user.merchantid;
            req = req.clone({
                setHeaders: {
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*',
                    Authorization: `Bearer ` + this.jwtHelper.tokenGetter()
                }
            });
        }
        // this.error.request(req);

        return next.handle(req).pipe(tap((event: HttpEvent<any>) => {
            if (event instanceof HttpResponse) {
                // do stuff with response if you want
                // console.log(event);
                if (event.body && event.ok) {
                    if(event.body.message) { 
                        // this.notify.success(event.body); 
                    }
                }
            }
        }, (err: any) => {
            if (err instanceof HttpErrorResponse) {
                if (err.status === 401) {
                    // this.notify.notify('Login Session Expired Please Login Again');
                    // auto logout if 401 response returned from api
                    if(this.jwtHelper.isTokenExpired) {
                        this.api.showMsg('error','UnAuthorize','Login Session Expired Please Login Again');
                        // localStorage.removeItem('fsp');
                        // this.router.navigate(['/login'], { queryParams: { returnUrl: window.location.pathname } });
                        // location.reload();
                    }
                    return false;
                }else {
                    const msg = err.error.message || err.message;
                    this.api.showMsg('error','Error',msg);
                }
            } else {
                // console.log(err);
                // console.log(err.error);
                // console.log(err.error.message);
                const msg = err.error.message || err.message;
                console.log(msg);
                // this.api.toastr.error(msg, 'Error');
                    this.api.showMsg('error','Error',msg);
            }
            // this.notify.error(msg);
        }));
        // return next.handle(req).pipe(catchError(err => {
        //     if (err.status === 401) {
        //         // auto logout if 401 response returned from api
        //         // this.toastr.error('Login Session Expired Please Login Again', 'UnAuthorize');
        //         localStorage.removeItem('fsp');
        //         // location.reload(true);
        //         this.router.navigate(['/login'], { queryParams: { returnUrl: this.state.url } });
        //     }
        //     this.error.log(err.error);
        //     const error = err.error.message || err.statusText;
        //     return throwError(error);
        // }));
    }
}

