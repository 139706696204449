import { Component, OnInit } from "@angular/core";
import { ManagerService } from "src/app/services/manager.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ExportService } from "src/app/services/export.service";
import { SetupService } from "src/app/services/setup.service";
import { ApiService } from "src/app/services/api.service";
import { ActivatedRoute } from "@angular/router";
import { environment } from "src/environments/environment";

declare var $: any;
@Component({
  selector: "app-payout",
  templateUrl: "./payout.component.html",
  styleUrls: ["./payout.component.sass"],
})
export class PayoutComponent implements OnInit {
  collection: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  exp: any[];
  id: number;
  type: any[] = ["RECEIVE", "SEND"];
  methods: any[] = ["MOBILE MONEY", "BANK"];
  networks: any[] = ["MTN", "VODAFONE", "AIRTEL-TIGO", "GLO"];
  view: boolean = false; report: boolean = false;
  tran: any;
  response:any; search = '';
  payout: any;
  saving: boolean = false;
  customers: any[] = [];
  sh: any;
  isChecked: boolean = true;
  countries: any[] = [];  res: any;  edit: boolean;
  // tslint:disable-next-line:variable-name
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
    // this.filtered = this.searchResult(value);
  }

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ext: ExportService,
    private use: NavComponent
  ) {}

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data)=> {
      ;
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';
      
      this.getAllPayouts(page,limit,search);
    })
    this.getMerchant();
    this.init();
    // this.getAllCustomer();
    // this.getAllCountries();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, start: "", end: "", settled: null, type: null, code: null }
  }

  getMerchant() {
    this.api.get('merchant?page=1&limit=50').subscribe((data: any) => {
        this.merchants = data;
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchant List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1,12,this.search);
  }

  getAllPayouts(page=1,limit=12,search='') {
    this.api.get(`Payout/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
        this.collection = data.data;
        this.response = data;
        console.log(data)
        // this.getTotal(data);
        setTimeout(() => {
          $(".footable").footable();
        }, 1000);
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch cashouts List"
        );
      }
    );
  }

  UpdateAll(){
    this.api.get(`Payout/RefreshAllPending?page=1&limit=1000&search=`).subscribe((data: any) => {
      // this.collection = data.data;
      // this.filtered = data;
      // this.response = data;
    },(err: HttpErrorResponse) => {
        this.use.showMsg("error", "Fetching Fail", "Unable to Fetch Payouts List");
    });
  }

  getAllCustomer() {
    this.api.get('Customers').subscribe((data: any) => {
      this.customers = data; this.filtered = data.data;
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Customer List');
    });
  }

  getAllCountries() {
    this.api.get('Country/GetAll').subscribe((data: any) => {
      this.countries = data.data; 
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Country List');
    });
  }


  getTranStatus(val) {
    this.api.get('Integration/v1/GetTrans?order_id='+val.code).subscribe((data: any) => {
      // this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  addCashout() {
    this.saving = true;
    this.api.post('Payout/Post', this.payout).subscribe((data: any) => {
      this.getAllPayouts();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Cashout Added', 'Cashout Created Successfully');
    }, (err: HttpErrorResponse) => {
      this.saving = false;
      this.use.showMsg('error', 'Cashout Creation Failed', err.message || 'Unable to Create Cashout');
    });
  }

  deleteCashout(val) {
    this.api.delete("Payout/DeletePayout/" + val.id).subscribe(
      (data: any) => {
        this.getAllPayouts();
        this.use.showMsg('success', 'Deleted', 'Cashout Was Deleted Successfully');
      }, (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Delete Cashout"
        );
      }
    );
  }

  putCashout() {
    this.payout.muserid = this.use.active.id; this.payout.mdate = new Date();
    this.api.put('Payout/Put/' + this.payout.id, this.payout).subscribe((data: any) => {
      this.res = data; this.getAllPayouts();
      document.getElementById('close').click();
      this.use.showMsg('success', 'Cashout Updated', 'Cashout Updated Successfully');
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Update Fail', 'Unable to Update Cashout');
    });
  }

  editCol(data) {
    this.payout = data;
    this.edit = true;
  }

  generate() {
    if (this.rpt.type == "SEND") {
      this.rpt.settled = true;
    }
    this.api.post("cashouts/Report", this.rpt).subscribe(
      (data: any) => {
        this.collection = data;
        this.filtered = data;
        // this.getTotal(data);
        setTimeout(() => {
          $(".footable").footable();
        }, 1000);
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Cashout List"
        );
      }
    );
  }


  export() {
    if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "items");
      this.use.showMsg(
        "success",
        "Cashout Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Payouts Export Fail",
        "There is no Cashout Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    this.filtered.forEach((e: any) => {
      const ed = {
        SN: this.id,
        CODE: e.tranid,
        ACCOUNT: e.account,
        TYPE: e.type,
        MOBILE: e.mobile,
        NETWORK: e.network,
        SERVICE: e.service,
        AMOUNT: e.amount,
        TRANSACTION_REF: e.transaction_ref,
        REFERENCE: e.reference,
        RESPONSE: e.response,
        CREATEDDATE: e.date,
      };
      this.exp.push(ed);
      this.id++;
    });
    return this.exp;
  }

  searchResult(searchString: string) {
    
    // tslint:disable-next-line:max-line-length
    return this.collection.filter(
      (a) =>
        a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
        a.network
          .toLowerCase()
          .indexOf(
            searchString.toLocaleLowerCase() ||
              a.mobile.indexOf(searchString) !== -1 ||
              a.reference.indexOf(searchString) !== -1
          ) !== -1
    );
  }

  init() {
    this.payout = { 
      merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support')? null:this.use.active.merchantid, 
      type: null, 
      method: null, 
      customerid: null,
      mobile: '',   
      source: '',  
      accountnumber: '',
      network: null,
      reference: '',
      settled: true,
      currency: environment.currency,
      amount: 0,  
      charge: 0,  
      commision: 0, 
      userid: this.use.active.id, 
      date: new Date(), 
      mdate: new Date(),
      muserid: null, 
    };
  }
}
