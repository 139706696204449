import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './views/login/login.component';
import { ForgetPasswordComponent } from './views/login/forget-password/forget-password.component';
import { LockComponent } from './views/login/lock/lock.component';
import { NavComponent } from './traits/nav/nav.component';
import { AuthGuard } from './guard/auth.guard';
import { DashboardComponent } from './views/dashboard/dashboard.component';
import { AdminGuard } from './guard/admin.guard';
import { ExitpageGuard } from './guard/exitpage.guard';
import { AccountGuard } from './guard/account.guard';
import { SetupSmsComponent } from './views/setup/setup-sms/setup-sms.component';
import { SetupCompanyComponent } from './views/setup/setup-company/setup-company.component';
import { SupportGuard } from './guard/support.guard';
import { SetupBranchComponent } from './views/setup/setup-branch/setup-branch.component';
import { BranchGuard } from './guard/branch.guard';
import { SetupSequenceComponent } from './views/setup/setup-sequence/setup-sequence.component';
import { SetupSessionComponent } from './views/setup/setup-session/setup-session.component';
import { SetupSmslogsComponent } from './views/setup/setup-smslogs/setup-smslogs.component';
import { SetupUserComponent } from './views/setup/setup-user/setup-user.component';
import { SetupLocationComponent } from './views/setup/setup-location/setup-location.component';
import { SetupChargeComponent } from './views/setup/setup-charge/setup-charge.component';
import { NotfoundComponent } from './views/error/notfound/notfound.component';
import { AlertsComponent } from './views/alerts/alerts.component';
import { SubcribersComponent } from './views/manager/subcribers/subcribers.component';
// import { PaymentsComponent } from './views/manager/payments/payments.component';
import { UtilityComponent } from './views/manager/utility/utility.component';
import { SettlementComponent } from './views/manager/settlement/settlement.component';
// import { PaymentComponent } from './views/manager/utility/payment/payment.component';
import { TopupComponent } from './views/manager/topup/topup.component';
import { TopUpsComponent } from './views/manager/utility/top-ups/top-ups.component';
import { LogsComponent } from './views/manager/utility/logs/logs.component';

import { CustomersComponent } from './views/customers/customers.component';
import { ViewCustomerComponent } from './views/customers/view-customer/view-customer.component';
import { UssdComponent } from './views/ussd/ussd.component';
import { RefundComponent } from './views/payments/refund/refund.component';
import { WalletComponent } from './views/payments/wallet/wallet.component';
import { SmsComponent } from './views/sms/sms.component';
import { SmsLogsComponent } from './views/sms/sms-logs/sms-logs.component';
import { DisputeComponent } from './views/payments/dispute/dispute.component';
import { SessionComponent } from './views/ussd/session/session.component';
import { UssdPackagesComponent } from './views/ussd/ussd-packages/ussd-packages.component';
import { BillingsComponent } from './views/ussd/billings/billings.component';
import { CollectionComponent } from './views/payments/collection/collection.component';
import { PayoutComponent } from './views/payments/payout/payout.component';
import { BulkpayComponent } from './views/payments/bulkpay/bulkpay.component';
import { ApiKeyComponent } from './views/api-key/api-key.component';
import { BillsComponent } from './views/bills/bills.component';
import { CreateBillComponent } from './views/bills/create-bill/create-bill.component';
import { UploadBillComponent } from './views/bills/upload-bill/upload-bill.component';
import { SetupPaymentsComponent } from './views/setup/setup-payments/setup-payments.component';
import { PaymentComponent } from './views/payment/payment.component';
import { PaymentsComponent } from './views/manager/payments/payments.component';
import { PlanDetailsComponent } from './views/recurring/plans/plan-details/plan-details.component';
import { PlansComponent } from './views/recurring/plans/plans.component';
import { SubscriberDetailsComponent } from './views/recurring/subscribers/subscriber-details/subscriber-details.component';
import { SubscribersComponent } from './views/recurring/subscribers/subscribers.component';
import { SubscriptionDetailsComponent } from './views/recurring/subscriptions/subscription-details/subscription-details.component';
import { SubscriptionsComponent } from './views/recurring/subscriptions/subscriptions.component';
import { SetupCountryComponent } from './views/setup/setup-country/setup-country.component';
import { InvoicesComponent } from './views/commerce/invoices/invoices.component';
import { CreateInvoiceComponent } from './views/commerce/invoices/create-invoice/create-invoice.component';
import { PaymentPagesComponent } from './views/commerce/payment-pages/payment-pages.component';
import { PaymentDetailsComponent } from './views/commerce/payment-pages/payment-details/payment-details.component';
import { InvoiceDetailComponent } from './views/commerce/invoices/invoice-detail/invoice-detail.component';
import { SetupApiComponent } from './views/setup/setup-api/setup-api.component';
import { CheckoutComponent } from './views/manager/checkout/checkout.component';
import { ViewSettlementComponent } from './views/manager/settlement/view-settlement/view-settlement.component';
import { ViewRefundComponent } from './views/payments/refund/view-refund/view-refund.component';
import { ViewCollectionComponent } from './views/payments/collection/view-collection/view-collection.component';
import { SetupCategoryComponent } from './views/setup/setup-category/setup-category.component';
import { SetupIndustryComponent } from './views/setup/setup-industry/setup-industry.component';
import { SetupRegistrationComponent } from './views/setup/setup-registration/setup-registration.component';
import { SetupRegistrationTypeComponent } from './views/setup/setup-registration-type/setup-registration-type.component';
import { SetupPartnersComponent } from './views/setup/setup-partners/setup-partners.component';
import { SetupTeamComponent } from './views/setup/setup-team/setup-team.component';
import { ComplianceComponent } from './views/compliance/compliance.component';
import { DetailsComponent } from './views/compliance/details/details.component';
import { NewComponent } from './views/compliance/new/new.component';
import { SetupBankComponent } from './views/setup/setup-bank/setup-bank.component';
import { SetupAccountComponent } from './views/setup/setup-account/setup-account.component';


const routes: Routes = [
{ path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'forgot_password', component: ForgetPasswordComponent },
  { path: 'lock', component: LockComponent, data: { title: 'Lock' } },
  {
    path: '', canActivate: [AuthGuard], component: NavComponent,
    children: [
      { path: '', component: DashboardComponent },
      { path: 'dashboard', component: DashboardComponent },
      { path: 'alerts', component: AlertsComponent, data: { title: 'Alerts' }, canActivate: [AccountGuard] },
      { path: 'payment', component: PaymentComponent, data: { title: 'payment' }, canActivate: [AccountGuard] },
      { path: 'topup', component: TopupComponent, data: { title: 'topup' }, canActivate: [AccountGuard] },
      { path: 'settlements', component: SettlementComponent, data: { title: 'settlement' }, canActivate: [AccountGuard] },

      // { path: 'ussd', component: UssdComponent, data: { title: 'USSD' }, canActivate: [AccountGuard] },
      { path: 'ussd', canActivate: [AuthGuard, AccountGuard],
        children: [
          { path: '', component: UssdComponent, canActivate: [AccountGuard] },
          { path: 'session', component: SessionComponent, canActivate: [AccountGuard] },
          { path: 'packages', component: UssdPackagesComponent, canActivate: [SupportGuard] },
          { path: 'billing', component: BillingsComponent, canActivate: [SupportGuard] },
        ]
      },

      {
        path: 'compliance', canActivate: [SupportGuard],
        children: [
          { path: '', component: ComplianceComponent, canActivate: [SupportGuard] },
          { path: 'details', component: DetailsComponent, canActivate: [SupportGuard] },
          { path: 'new', component: NewComponent, canActivate: [SupportGuard] },
        ]
      },

      { path: 'manager', canActivate: [AuthGuard, AccountGuard],
        children: [
          // { path: 'subcribers', component: SubcribersComponent, canActivate: [SupportGuard] },
          { path: 'topups', component: TopUpsComponent, canActivate: [SupportGuard] },
          { path: 'payments', component: PaymentsComponent, canActivate: [SupportGuard] },
          { path: 'payments/logs', component: LogsComponent, canActivate: [SupportGuard] },
          { path: 'utility', component: UtilityComponent, canActivate: [SupportGuard] },
          { path: 'checkout', component: CheckoutComponent, canActivate: [SupportGuard] },
        ]
      },

      { path: 'refund', component: RefundComponent, data: { title: 'Refund' }, canActivate: [AccountGuard] },

      { path: 'wallets', component: WalletComponent, data: { title: 'Wallets' }, canActivate: [AccountGuard] },

      { path: 'api-key', component: ApiKeyComponent, data: { title: 'Wallets' }, canActivate: [AccountGuard] },

      { path: 'disputes', component: DisputeComponent, data: { title: 'Disputes' }, canActivate: [AccountGuard] },

      { path: 'sms', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: '', component: SmsComponent, canActivate: [AccountGuard] },
          { path: 'logs', component: SmsLogsComponent, canActivate: [AccountGuard] },
          { path: 'alerts', component: AlertsComponent, data: { title: 'Alerts' }, canActivate: [AccountGuard] },
        ]
      },

      {
        path: 'recurring', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: 'subscribers', component: SubscribersComponent, canActivate: [SupportGuard] },
          { path: 'subscribers/view/:id', component: SubscriberDetailsComponent, canActivate: [SupportGuard] },
          { path: 'plans', component: PlansComponent, canActivate: [SupportGuard] },
          { path: 'plans/view/:id', component: PlanDetailsComponent, canActivate: [SupportGuard] },
          { path: 'subscriptions', component: SubscriptionsComponent, canActivate: [SupportGuard] },
          { path: 'subscriptions/view/:id', component: SubscriptionDetailsComponent, canActivate: [SupportGuard] },
        ]
      },

      {
        path: 'commerce', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: 'invoice', component: InvoicesComponent, data: { title: 'Invoices' }, canActivate: [AccountGuard] },
          { path: 'invoice/create', component: CreateInvoiceComponent, data: { title: 'Create Invoices' }, canActivate: [AccountGuard] },
          { path: 'invoice/detail', component: InvoiceDetailComponent, data: { title: 'Invoice Details' }, canActivate: [AccountGuard] },
          { path: 'payment-pages', component: PaymentPagesComponent, data: { title: 'Payment Pages' }, canActivate: [AccountGuard] },
          { path: 'payment-pages/view/:id', component: PaymentDetailsComponent, data: { title: 'Payment Page Details' }, canActivate: [AccountGuard] },
        ]
      },

      { path: 'customers', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: '', component: CustomersComponent },
          { path: 'view/:id', component: ViewCustomerComponent, canActivate: [AccountGuard] },
        ]
      },

      { path: 'settlements', canActivate: [AuthGuard,AccountGuard],
        children: [
          { path: '', component: SettlementComponent },
          { path: 'view/:id', component: ViewSettlementComponent },
        ]
      },

      { path: 'bills', canActivate: [AuthGuard, AccountGuard],
        children: [
          { path: '', component: BillsComponent },
          { path: 'create', component: CreateBillComponent },
          { path: 'bulk-upload', component: UploadBillComponent },
        ]
      },

      { path: 'payments', canActivate: [AuthGuard, AccountGuard],
      children: [
        { path: 'cashout', component: PayoutComponent, canActivate: [AccountGuard] },
        { path: 'collection', component: CollectionComponent, canActivate: [AccountGuard] },
        { path: 'collection/view/:id', component: ViewCollectionComponent,  canActivate: [AccountGuard] },
        { path: 'bulkpay', component: BulkpayComponent, canActivate: [AccountGuard] },
        { path: 'refund', component: RefundComponent,  canActivate: [AccountGuard] },
        { path: 'refund/details/:id', component: ViewRefundComponent,  canActivate: [AccountGuard] },
        { path: 'disputes', component: DisputeComponent,  canActivate: [AccountGuard] },
        { path: 'wallets', component: WalletComponent,  canActivate: [AccountGuard] },
      ]
    },
    
      { path: 'setup', canActivate: [AuthGuard, AccountGuard],
        children: [
          { path: '', component: SetupSmsComponent, canActivate: [AdminGuard] },
          { path: 'merchants', component: SetupCompanyComponent, canActivate: [SupportGuard] },
          { path: 'branch', component: SetupBranchComponent, canActivate: [BranchGuard] },
          { path: 'sequence', component: SetupSequenceComponent, canActivate: [AdminGuard] },
          { path: 'country', component: SetupCountryComponent, canActivate: [AdminGuard] },
          { path: 'category', component: SetupCategoryComponent, canActivate: [AdminGuard] },
          { path: 'industry', component: SetupIndustryComponent, canActivate: [AdminGuard] },
          { path: 'registrationtype', component: SetupRegistrationComponent, canActivate: [AdminGuard] },
          { path: 'registration', component: SetupRegistrationTypeComponent, canActivate: [AdminGuard] },
          { path: 'partner', component: SetupPartnersComponent, canActivate: [AdminGuard] },
          { path: 'banks', component: SetupBankComponent, canActivate: [SupportGuard] },
          { path: 'accounts', component: SetupAccountComponent, canActivate: [SupportGuard] },
          { path: 'charges', component: SetupChargeComponent, canActivate: [SupportGuard] },
          { path: 'session', component: SetupSessionComponent, canActivate: [BranchGuard] },
          { path: 'smslog', component: SetupSmslogsComponent, canActivate: [AdminGuard] },
          { path: 'users', component: SetupUserComponent, canActivate: [SupportGuard] },
          { path: 'alerts', component: AlertsComponent, data: { title: 'Alerts' }, canActivate: [AdminGuard] },
          { path: 'sms', component: SetupSmsComponent, data: { title: 'Sms' }, canActivate: [AdminGuard]  },
          { path: 'api', component: SetupApiComponent, canActivate: [AdminGuard]  },
          { path: 'location', component: SetupLocationComponent, canActivate: [SupportGuard, AdminGuard] },
          { path: 'payments', component: SetupPaymentsComponent, canActivate: [SupportGuard, AdminGuard] },
          { path: 'teams', component: SetupTeamComponent, canActivate: [AdminGuard] },
        ]
      },
      { path: '404', component: NotfoundComponent, data: { title: 'Error Page' } },
      { path: '**', redirectTo: '404' }
    ]
  },
  { path: '**', redirectTo: '404' }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
