import { Component, OnInit } from "@angular/core";
import { ManagerService } from "src/app/services/manager.service";
import { NavComponent } from "src/app/traits/nav/nav.component";
import { HttpErrorResponse } from "@angular/common/http";
import { ExportService } from "src/app/services/export.service";
import { SetupService } from "src/app/services/setup.service";
import { ActivatedRoute } from "@angular/router";
import { ApiService } from "src/app/services/api.service";

declare var $: any;
@Component({
  selector: "app-collection",
  templateUrl: "./collection.component.html",
  styleUrls: ["./collection.component.sass"],
})
export class CollectionComponent implements OnInit {
  payments: any[] = [];
  collection: any[] = [];
  filtered: any[] = [];
  total = 0;
  merchants: any[] = [];
  rpt: any;
  exp: any[];
  id: number;
  type: any[] = ["RECEIVE", "SEND"];
  view: boolean = false; report: boolean = false;
  tran: any;
  response: any; reports: any; search = '';
  private _searhcTitle: string;

  get searchTitle(): string {
    return this._searhcTitle;
  }

  set searchTitle(value: string) {
    this._searhcTitle = value;
    this.search = this._searhcTitle;
  }

  constructor(
    private api: ApiService,
    private route: ActivatedRoute,
    private ext: ExportService,
    private use: NavComponent
  ) { }

  ngOnInit() {
    $.getScript("assets/js/plugins/footable/footable.all.min.js");
    this.route.queryParams.subscribe((data) => {
      const page = data.page ? data.page : 1;
      const limit = data.limit ? data.limit : 12;
      const search = data.search ? data.search : '';

      this.getAllCollections(page, limit, search);
    });
    this.getMerchants();
    this.rpt = { merchantid: (this.use.active.typ === 'Admin' || this.use.active.typ === 'Support') ? null : this.use.active.merchantid, type: null, settled: null, code: 0, limit: 1000 }
  }

  getMerchants() {
    this.api.get('merchant?page=1&limit=50').subscribe((data: any) => {
      this.merchants = data;
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Merchants List"
        );
      }
    );
  }

  getSearch() {
    this.api.updateUrl(1, 12, this.search);
  }

  getAllCollections(page = 1, limit = 12, search = '') {
    this.api.get(`Collection/GetAll?page=${page}&limit=${limit}&search=${search}`).subscribe((data: any) => {
      this.collection = data.data;
      this.filtered = data;
      this.response = data;
    },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Collections List"
        );
      }
    );
  }
  
  UpdateAll(){
    this.api.get(`Collection/RefreshAllPending?page=1&limit=1000&search=`).subscribe((data: any) => {
      // this.collection = data.data;
      // this.filtered = data;
      // this.response = data;
    },(err: HttpErrorResponse) => {
        this.use.showMsg("error", "Fetching Fail", "Unable to Fetch Collections List");
    });
  }

  getTranStatus(val) {
    this.api.get('Integration/v1/GetTrans?order_id=' + val.code).subscribe((data: any) => {
      // this.getAllPayment();
    }, (err: HttpErrorResponse) => {
      this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
    });
  }

  viewTran(val) {
    this.tran = val;
    this.view = true;
  }

  generate() {
    this.api.post(`collections/Report?search=${this.search}&limit=${this.rpt.limit}`, this.rpt).subscribe(
      (data: any) => {
        this.payments = data.data;
        this.reports = data;
        this.use.showMsg('error', 'Fetching Fail', 'Unable to Fetch Payment List');
      },
      (err: HttpErrorResponse) => {
        this.use.showMsg(
          "error",
          "Fetching Fail",
          "Unable to Fetch Collections List"
        );
      }
    );
  }

  export() {
    if (this.payments.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "collection-report");
      this.use.showMsg(
        "success",
        "Collections Exported",
        "Check Downloaded Excel File For Details"
      );
    } else if (this.filtered.length > 0) {
      const data = this.format();
      this.ext.exportAsExcelFile(data, "collection");
      this.use.showMsg(
        "success",
        "Collections Exported",
        "Check Downloaded Excel File For Details"
      );
    } else {
      this.use.showMsg(
        "error",
        "Collections Export Fail",
        "There is no Collections Data to Export"
      );
    }
  }

  format() {
    this.id = 1;
    this.exp = [];
    if (this.payments.length > 0) {
      this.filtered.forEach((e: any) => {
        const ed = {
          SN: this.id,
          CODE: e.tranid,
          // ACCOUNT: e.account,
          // TYPE: e.type,
          MOBILE: e.mobile,
          NETWORK: e.network,
          SERVICE: e.service,
          AMOUNT: e.amount,
          CHARGE: e.amount,
          SETTLED_AMOUNT: e.Settle_amount,
          TRANSACTION_REF: e.orderid,
          PROVIDER: e.provider,
          TRANSACTION_NO: e.transaction_no,
          REFERENCE: e.reference,
          STATUS: e.status_message,
          CREATEDDATE: e.date,
        };
        this.exp.push(ed);
        this.id++;
      });
    } else {
      this.filtered.forEach((e: any) => {
        const ed = {
          SN: this.id,
          CODE: e.tranid,
          TYPE: e.type,
          MOBILE: e.mobile,
          NETWORK: e.network,
          SERVICE: e.service,
          AMOUNT: e.amount,
          TRANSACTION_REF: e.transaction_ref,
          REFERENCE: e.reference,
          RESPONSE: e.response,
          CREATEDDATE: e.date,
        };
        this.exp.push(ed);
        this.id++;
      });
    }
    return this.exp;
  }

  searchResult(searchString: string) {
    return this.collection.filter(
      (a) =>
        a.type.toLowerCase().indexOf(searchString.toLocaleLowerCase()) !== -1 ||
        a.network
          .toLowerCase()
          .indexOf(
            searchString.toLocaleLowerCase() ||
            a.mobile.indexOf(searchString) !== -1 ||
            a.reference.indexOf(searchString) !== -1
          ) !== -1
    );
  }
}
